import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import PostHeader from '../components/post-header'
import Sidebar from '../components/sidebar'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#1f232b', paddingTop: '50px' }}>
          <Helmet title={`${post.title} | ${siteTitle}`} bodyAttributes={{
            class: 'weefly-main'
          }} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-sm-7 col-md-8 col-lg-8">
                  <article className="single-post vertical-item content-padding big-padding with_background post rounded overflow_hidden post-listing">
                    <PostHeader heroImage={post.heroImage} profileImage={post.author.profileImage} isoDate={post.isoDate} publishDate={post.publishDate} slug={post.slug} tags={post.tags} />
                    <div className="item-content">
                      <header className="entry-header">
                        <h3 className="entry-title" style={{ color: '#669543' }}>{post.title}</h3>
                      </header>
                      <div className="entry-content">
                        <p dangerouslySetInnerHTML={{
                          __html: post.body.childMarkdownRemark.html,
                        }} />
                      </div>
                    </div>
                  </article>
                  <article className="author-meta side-item side-md content-padding big-padding with_background rounded overflow_hidden post-listing" style={{ marginBottom: '60px' }}>
                      <div className="row">
                          <div className="col-md-4">
                              <div className="item-media top_rounded overflow_hidden"> 
                                <Img alt={post.author.name} fixed={post.author.largeProfileImage.fixed} />
                              </div>
                          </div>
                          <div className="col-md-8">
                              <div className="item-content">
                                  <header className="entry-header content-justify">
                                      <h4 className="bottommargin_0">{post.author.name}</h4>
                                      {/* <p className="small-text highlight">Admin</p> */}
                                  </header>
                                  <p className="bio" dangerouslySetInnerHTML={{
                                    __html: post.author.shortBio.childMarkdownRemark.html,
                                  }} />
                                  <div className="social-links">
                                    {post.author.instagram && (
                                      <a className="social-icon border-icon rounded-icon" href={`https://www.instagram.com/${post.author.instagram}/`} rel="noreferrer" title="Instagram" target="_blank">
                                        <i className="fab fa-instagram"></i>
                                      </a>
                                    )}
                                    {post.author.twitter && (
                                      <a className="social-icon border-icon rounded-icon" href={`https://twitter.com/${post.author.twitter}/`} rel="noreferrer" title="Twitter" target="_blank">
                                        <i className="fab fa-twitter"></i>
                                      </a>
                                    )}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </article>
                </div>
                <Sidebar/>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      isoDate: publishDate
      publishDate(formatString: "MMMM Do, YYYY")
      tags
      author {
        contentful_id
        name
        instagram
        twitter
        profileImage: image {
          fixed(width: 80, height: 80) {
            ...GatsbyContentfulFixed
          }
        }
        largeProfileImage: image {
          fixed(height: 300) {
            ...GatsbyContentfulFixed
          }
        }
        shortBio {
          childMarkdownRemark {
            html
          }
        }
      }
      heroImage {
        fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
